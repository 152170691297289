import { useEffect, useState } from 'react';

import { updateChainId } from './reducer'
import { useWeb3Context } from '../../../../libs/hooks/useWeb3Context';
import { useAppDispatch } from '../store';
import useIsWindowVisible from '../../hooks/useIsWindowVisible';
import useDebounce from '../../hooks/useDebounce';
import { useRootStore } from '../../../../store/root';
import { useMarketsQuery } from '../../../../hooks/yldr/useMarketsQuery';

export const ChainIdUpdater = (): null => {
  const { chainId, provider } = useWeb3Context();
  const dispatch = useAppDispatch();
  const windowVisible = useIsWindowVisible();

  const [activeChainId, setActiveChainId] = useState(chainId);

  useEffect(() => {
    if (provider && chainId && windowVisible) {
      setActiveChainId(chainId);
    }
  }, [dispatch, chainId, provider, windowVisible]);

  const debouncedChainId = useDebounce(activeChainId, 100);

  useEffect(() => {
    const chainId = debouncedChainId ? debouncedChainId : null;
    dispatch(updateChainId({ chainId }));
  }, [dispatch, debouncedChainId]);

  return null;
};

export const MarketsUpdater = (): null => {
  const { data, isSuccess } = useMarketsQuery();
  const updateMarketData = useRootStore((state) => state.updateMarketData);

  useEffect(() => {
    if (isSuccess) {
      updateMarketData(data, isSuccess);
    }
  }, [data, isSuccess])

  return null;
}
